<script setup lang="ts">
import type { ModalTypeSummary } from '~/types/wishlist'

const { fetchWishlists, fetched, wishlists } = useMultipleWishlist()
const { wishlistActive, setWishlistActive, summaryOpen, setSummaryOpen } =
  useMultipleWishlistSummary()

const modalStep = ref<ModalTypeSummary>()

const changeStep = (step: ModalTypeSummary) => {
  modalStep.value = step
}

const closeModal = () => {
  setSummaryOpen(false)
  modalStep.value = undefined
}

const wishlistDeleted = () => {
  setWishlistActive(null)
  closeModal()
}

// if multiple tabs open need to sync wishlists
watch(summaryOpen, async (value) => {
  if (value) await fetchWishlists()
})
</script>

<template>
  <BaseModalV2
    :model-value="summaryOpen"
    size="s"
    active-esc-mode
    @close="closeModal"
    @update:model-value="closeModal"
  >
    <template #content>
      <div v-show="!modalStep">
        <WishlistMultipleSummaryList
          v-if="fetched && wishlists.length"
          @open-modal="changeStep"
          @inquiry-sended="changeStep('inquiryInfo')"
        />
        <WishlistMultipleSummaryEmptyList v-else />
      </div>
      <WishlistMultipleModalWrapperParameters
        v-if="modalStep === 'parameters'"
        @step="changeStep"
      />
      <WishlistMultipleModalWrapperShare
        v-else-if="modalStep === 'share' && wishlistActive"
        :wishlist-token="wishlistActive.privateToken"
      />
      <WishlistMultipleModalWrapperConfirmDelete
        v-else-if="modalStep === 'delete' && wishlistActive"
        :wishlist-title="wishlistActive.title"
        :wishlist-token="wishlistActive.privateToken"
        @wishlist-deleted="wishlistDeleted"
      />
      <WishlistMultipleModalWrapperForm
        v-else-if="modalStep === 'edit' && wishlistActive"
        edition
        :edtion-token="wishlistActive.privateToken"
        :title="wishlistActive.title"
        @wishlist-updated="closeModal"
      />
      <WishlistMultipleModalWrapperForm
        v-else-if="modalStep === 'add'"
        :title="$t('wishlistMultiple.createNewList')"
        @wishlist-created="closeModal"
      />
      <WishlistMultipleSummaryInquiry
        v-else-if="modalStep === 'inquiryInfo'"
        @inquiry-finished="changeStep(undefined)"
      />
    </template>
  </BaseModalV2>
</template>
