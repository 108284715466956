import type { CoreGroupFields } from '~/components/BaseForm/types'
import { Email } from '~/components/BaseForm/core/fields'

export default (): CoreGroupFields => {
  const email: typeof Email = {
    ...Email,
    attr: {
      ...Email.attr,
      placeholderTranslateKey: 'popinNewsletter.email',
      labelTranslateKey: '',
    },
    containerInputClass: 'w-full px-2',
  }

  return {
    containerGroupFieldsClass: 'flex flex-wrap -mx-2',
    fields: [email],
  }
}
